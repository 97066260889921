import React from 'react';

export default function OrBar(props) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 9, paddingBottom: 12, color: '#aaa' }}>
      <div style={{ height: 1, backgroundColor: '#aaa', flex: 1, marginRight: 10 }}/>
      <div style={{ position: 'relative', top: '-2px' }}>or</div>
      <div style={{ height: 1, backgroundColor: '#aaa', flex: 1, marginLeft: 10 }}/>
    </div>
  );
}
