import React, { useState } from 'react';
import { isHostMobile } from '../helpers/host';
import LinkListFooter from './LinkListFooter';
import { getExpectedUrlParams } from '../helpers/urlParams';
import { executeTokenPayment, recordRosswarepayTransaction } from '../helpers/api';
import { Alert } from 'react-bootstrap';
import OrBar from './OrBar';
import ChargeBreakdown from './ChargeBreakdown';

const params = getExpectedUrlParams();

export default function UseTokenScreen(props) {
  const { token, switchScreen, onCancel, setGlobalError, onPaymentSuccess, modifiedAmounts } = props;
  const [processing, setProcessing] = useState(false);
  const [smallError, setSmallError] = useState(null);

  return (
    <div className="fullsteampay-form">
      <div className="manual-entry-header">
        <h4>Charge card on file</h4>
      </div>

      <ChargeBreakdown modifiedAmounts={modifiedAmounts} />

      <div className="padded-div">

        <table className="table">
          <tbody>
            <tr><td>Nickname</td><td style={{ lineBreak: 'anywhere' }}><strong>{token?.nickname}</strong></td></tr>
            <tr><td style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Account Holder</td><td style={{ lineBreak: 'anywhere' }}>{token?.customer_name}</td></tr>
            <tr><td>Card</td><td style={{ lineBreak: 'anywhere' }}>**** **** **** {token?.account_last_4}</td></tr>
            <tr><td>Expires</td><td style={{ lineBreak: 'anywhere' }}>{token?.expiration_date}</td></tr>
          </tbody>
        </table>

        <p style={{ fontSize: 12, color: 'red' }}>Please be very sure you're charging the correct card!</p>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
          <button disabled={processing} onClick={async () => {
            setSmallError(null);
            setProcessing(true);
            const executeResponse = await executeTokenPayment({
              token: token?.token,
              amount: modifiedAmounts?.total,
              invoice_number: params?.invoice_number,
              force_duplicate: false,
            });

            const apiResponse = await executeResponse?.json?.();
            console.log('execute token results', executeResponse, apiResponse);

            if (!!apiResponse?.data?.isSuccessful) {
              try {
                await recordRosswarepayTransaction({
                  unique_id: params?.unique_id,
                  fullsteam_response: { gatewayResponse: apiResponse.data },
                  invoice_number: params?.invoice_number,
                  method: 'rossware_pay_terminal',
                  tech_id: params?.requester_id,
                  platform_fee: modifiedAmounts.platform_fee,
                  platform_fee_basis: modifiedAmounts.platform_fee_amount,
                  requester_id: params.requester_id,
                  platform: params.platform,
                });

                onPaymentSuccess({ transaction_id: apiResponse?.data?.transactionID, unique_id: params?.unique_id, response: { gatewayResponse: apiResponse.data }, success: !!apiResponse?.data?.isSuccessful, done: true, tokenized: true })
              } catch (err) {
                setGlobalError(err);
              }
              clearInterval(window.pingerInterval);
              setProcessing(false);
            } else {
              const messageObjects = apiResponse?.data?.responseDetails;
              const messages = messageObjects?.map?.(messageObject => messageObject?.message)?.join?.(', ');
              setSmallError(`${apiResponse?.data?.responseCode} ${messages}`);
              setProcessing(false);
            }

          }} className={`btn btn-primary`}>Execute payment</button>
        </div>

        <OrBar />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button onClick={() => switchScreen('choose-token')} className="btn btn-secondary">Go to cards on file</button>
          <button style={{ marginTop: '0.5rem' }} onClick={() => switchScreen('manual')} className="btn btn-secondary">Enter card number</button>
          {!isHostMobile() && <button style={{ marginTop: '0.5rem' }} className="btn btn-primary cancel-button" onClick={(event) => {
            onCancel?.();
          }}>Cancel payment</button>}
        </div>
        { smallError && <Alert style={{ marginTop: '5px', marginBottom: '0' }} variant='danger'>{JSON.stringify(smallError)}</Alert>}
        <LinkListFooter style={{ marginTop: 32 }}/>
        {processing && (
          <div className="processingOverlay">
            <div className="processingOverlayContent">
              <div className="processingOverlayContentText">Processing...</div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
}
