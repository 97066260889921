import React from 'react';
import styles from './Modal.module.css';

export default function Modal(props) {
  const { title, children, style } = props;
  return (
    <div className={styles.modal}>
      <div className={styles['modal-content']} style={style}>
        {!!title && <div className={styles['modal-header']}>
          <h2>{title}</h2>
        </div>}

        {children}
      </div>
    </div>
  );
}
