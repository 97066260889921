import { useCallback, useEffect, useRef, useState } from 'react';
import { getRosswarepayEndpointUrl } from './api';
import { waitForCardElements } from './elementWaiters';
import { isHostMobile } from './host';

export function useFullsteam(settings) {
  console.log('use fullsteam hook', settings);
  const [validations, setValidations] = useState({});
  const [errors, setErrors] = useState(null);
  const passSettings = useRef({});
  const setupRef = useRef(false);

  const updateValidation = useCallback((key, value) => {
    setValidations(Object.assign({}, validations, { [key]: value }));
    if (settings.onValidation) {
      const currentlyValid = Object.values(Object.assign({}, validations, { [key]: value })).every((v) => v);
      settings.onValidation(currentlyValid);
    }
  }, [validations, setValidations, settings]);

  useEffect(() => {
    Object.assign(passSettings.current, settings, {
      cardNumberValid: () => {
        updateValidation('cc', true);
      },
      cardNumberInvalid: () => {
        updateValidation('cc', false);
      },

      cvvValid: () => {
        updateValidation('cvv', true);
      },
      cvvInvalid: () => {
        updateValidation('cvv', false);
      },

      expirationMonthValid: () => {
        updateValidation('expmonth', true);
      },
      expirationMonthInvalid: () => {
        updateValidation('expmonth', false);
      },

      expirationYearValid: () => {
        updateValidation('expyear', true);
      },
      expirationYearInvalid: () => {
        updateValidation('expyear', false);
      },
      hostedPaymentsSimpleError: (err) => {
        setErrors(err);
        settings?.onError?.(err);
      },
    }); // this allows us to pass a single object to the fullsteam sdk and have updates reflected in the sdk
  }, [settings, updateValidation]);

  // only setup when we have authenticated
  useEffect(() => {
    if (setupRef.current === false && settings?.scriptLoaded && settings?.authenticationKey) {
      console.log('setting up fs in hook', passSettings.current, 'vs', settings);
      window.setupFullsteamHostedPayments(passSettings.current);
      waitForCardElements().then(() => {
        settings.onLoad?.();
      });
      setupRef.current = true;
    }
  }, [settings, settings?.scriptLoaded, settings?.authenticationKey]);

  return {
    validations,
    errors,
  };
}

export function useFullsteamScript(uniqueId) {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!isHostMobile()) {
      fetch(`${getRosswarepayEndpointUrl('updateTerminalActivity')}?unique_id=${uniqueId}`).then(() => { console.log('Pinged API to indicate user is active'); }).then(() => {
        window.pingerInterval = setInterval(() => {
          fetch(`${getRosswarepayEndpointUrl('updateTerminalActivity')}?unique_id=${uniqueId}`).then(() => { console.log('Pinged API to indicate user is active'); })
        }, 7000);
      })
    }

    var script = document.createElement('script');
    script.id = 'fullsteam-hosted-payments-script';
    script.onload = function () {
      setScriptLoaded(true);
    };

    script.src = process.env.REACT_APP_ROSSWARE_PAY_ENVIRONMENT === 'test' ? 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js' : 'https://hostedpayments.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js';

    document.head.appendChild(script);

    return () => {
      clearInterval(window.pingerInterval);
      script.parentElement?.removeChild?.(script);
      script = null;
    }
  }, [uniqueId, setScriptLoaded]);

  return scriptLoaded;
}
