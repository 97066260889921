import { getExpectedUrlParams } from './urlParams';

export function getRosswarepayEndpointUrl(endpoint) {
	return `${process.env.REACT_APP_API_BASE_URL}/v3/rosswarepay/${process.env.REACT_APP_ROSSWARE_PAY_ENVIRONMENT || 'test'}/${endpoint}`;
}

export async function authenticatedFetch(url, config = {}) {
	const params = getExpectedUrlParams();

	let passBody;

	if (!config.method) config.method = 'GET';
	if (config.method.toUpperCase() === 'POST') {
		passBody = Object.assign({
			business_id: params?.business_id,
      business_password: params?.business_password,
		}, config?.body);
	}

	let passUrl = url;

	if (!config.params) {
		config.params = {};
	}

	Object.assign(config.params, {
		business_id: params?.business_id,
    business_password: params?.business_password,
	});

	passUrl += '?' + paramifyObject(config.params);

	return fetch(passUrl, {
		...config,
		body: JSON.stringify(passBody),
		cache: 'no-cache',
		headers: {
			...config?.headers,
			'Content-Type': 'application/json',
		},
	});
}

export function paramifyObject(object) {
	if (!object) return '';
	const keys = Object.keys(object);
	return keys.reduce((result, key) => {
		const value = object[key];
		if (value === null)  {
			result.push(`${encodeURIComponent(key)}=`); // blank value
		} else if (value !== undefined) { // undefined values are ignored
			result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
		}

		return result;
	}, []).join('&');
}

export async function recordRosswarepayTransaction({ unique_id, fullsteam_response, invoice_number, requester_id, platform_fee, platform_fee_basis, platform  }) {
	const response = await authenticatedFetch(getRosswarepayEndpointUrl('recordTransaction'), { params: {
    unique_id,
    response: JSON.stringify(fullsteam_response),
    invoice_number,
		requester_id,
		method: 'rossware_pay_terminal',
		app: 'terminal.rosswarepay',
		platform,
		platform_fee,
		platform_fee_basis,
  } })
  const data = await response.json();
  return data;
}

export async function captureRosswarePayTransaction({ force_duplicate, transaction_id, amount, invoice_number }) {
	const response = await authenticatedFetch(getRosswarepayEndpointUrl('capture'), { params: {
		force_duplicate,
    transaction_id,
		amount,
		invoice_number,
  } });

  return response.json();
}

export async function startRosswarepayTransaction(params) {
	const response = await authenticatedFetch(getRosswarepayEndpointUrl('startHostedTransaction'), { params: {
		unique_id: params.unique_id,
		payer_name: params.payer_name,
		requester_id: params?.requester_id,
		amount: params.amount,
		tax: params.tax,
		zip_code: params.zip_code,
		invoice_number: params.invoice_number,
		context: params.context,
	} });

	const data = await response.json();
  const code = response.status;

	return { status: response.status, code, data };
}

export async function executeRefund(params) {
	const response = await authenticatedFetch(getRosswarepayEndpointUrl('executeRefund'), { params: {
		token: params.token,
		amount: params.amount,
		invoice_number: params.invoice_number,
	} });

	const data = await response.json();
  const code = response.status;

	return { status: response.status, code, data };
}

export async function authRosswarepay({ amount, business_id, business_password, refund }) {
	let tokenContext;

	if (refund) {
		tokenContext = 'token';
	} else {
		tokenContext = 'payment';
	}

	const response = await authenticatedFetch(getRosswarepayEndpointUrl('auth'), { params: {
		amount,
		context: tokenContext,
	}});


	console.log('got auth response', response);
	const authData = await response.json();
	return authData;
}

/**
 *
 * @param {{token: string, transaction_id: string|number, invoice_number: string|number, account_last_4: string, nickname: string, description: string, business_certified_permission: boolean, customer_name: string, is_test: boolean, expiration_date: string, origin_app: string, brand: string}} data
 * @returns
 */
export function saveAsCardOnFile(data) {
	return authenticatedFetch(`${process.env.REACT_APP_API_BASE_URL}/v3/rosswarepay/savePaymentToken`, { method: 'POST', body: data });
}

/**
 *
 * @param {{ token: string, amount: string|number, invoice_number: string, force_duplicate: boolean, platform: string, tip_amount: string|number, subtotal_amount: string|number, tax_amount: string|number, card_entry_context: string|number }} data
 * @returns
 */
export function executeTokenPayment(data) {
	return authenticatedFetch(getRosswarepayEndpointUrl('executeTokenCharge'), { method: 'POST', body: data });
}

/**
 * @param {{ token: string, amount: string|number, transaction_id: string, invoice_number: string, recipient: string, signature: string, schedule_item_id: string, basis: string, accounted_in_work: boolean, source_app: string }} data
 * @returns {Promise<Response>}
 */
export function adjustTip(data) {
	return authenticatedFetch(getRosswarepayEndpointUrl('adjustTip'), { method: 'POST', body: data });
}
