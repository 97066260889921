import { formatCurrency } from "../helpers/formatting";

export default function ChargeBreakdown(props) {
  if (props?.modifiedAmounts?.platform_fee && props?.modifiedAmounts?.platform_fee > 0) {
    return (
      <div className="total-amount-container">
        <h1>{formatCurrency(props?.modifiedAmounts?.subtotal)}</h1>
        <h4>+ {formatCurrency(props?.modifiedAmounts?.platform_fee)} Platform fee</h4>
        <h4>Amount due: {formatCurrency(props?.modifiedAmounts?.total)}</h4>
      </div>
    )
  } else {
    return (
      <div className="total-amount-container">
        <h1>{formatCurrency(props?.modifiedAmounts?.subtotal)}</h1>
      </div>
    )
  }
}