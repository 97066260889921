import React, { Fragment } from 'react';
import ValidityLabel from './ValidityLabel';

export function LabeledRawValidityInput(props) {
  const {
    valid,
    title,
    className,
    id,
    value,
    defaultValue,
    onChange,
  } = props;

  return (
    <Fragment>
      <ValidityLabel valid={valid}>{title}</ValidityLabel>
      <input className={`${className} custom-input i-frame ${ !valid && 'fullSteamFilledDivInvalid' }`} id={id} defaultValue={defaultValue} value={value} onChange={(event) => onChange(event.target.value)}></input>
    </Fragment>
  );
}

export default function LabeledValidityInput(props) {
  const {
    valid,
    title,
    className,
    id,
    defaultValue,
    label,
    validator,
    onValidate,
    onChange,
  } = props;

  return (
    <LabeledRawValidityInput
      valid={valid}
      title={title}
      className={className}
      id={id}
      defaultValue={defaultValue}
      onChange={text => {
        onValidate(validator(label, text));
        onChange(text);
      }}
    />
  );
}
