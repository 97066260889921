export function hostedPaymentsRangeCheck(input, startValue, endValue) {
  const length = startValue.toString().length;
  const substring = input.substring(0, length);
  const intValue = parseInt(substring);

  return (startValue <= intValue && intValue <= endValue);
}

export function hostedPaymentsPerformBinCheck(value) {
    if (!value) return null;

    if (value.substring(0, 2) === '34' || value.substring(0, 2) === '37') return 'amex';
    if (value.substring(0, 2) === '62') return 'unionPay';
    if (hostedPaymentsRangeCheck(value, 300, 305)) return 'dinersClubCarteBlanche';
    if (value.substring(0, 2) === '36') return 'dinersClubInternational';
    if (value.substring(0, 4) === '6011' || value.substring(0, 2) === '65' || hostedPaymentsRangeCheck(value, 622126, 622925) || hostedPaymentsRangeCheck(value, 644, 649)) return 'discoverCard';
    if (value.substring(0, 3) === '636') return 'interPayment';
    if (hostedPaymentsRangeCheck(value, 637, 639)) return 'instaPayment';
    if (hostedPaymentsRangeCheck(value, 3528, 3589)) return 'JCB';
    if (value.substring(0, 2) === '50' || value.substring(0, 1) === '6' || hostedPaymentsRangeCheck(value, 56, 58)) return 'maestro';
    if (hostedPaymentsRangeCheck(value, 2221, 2720) || hostedPaymentsRangeCheck(value, 51, 55)) return 'masterCard';
    if (value.substring(0, 1) === '4') return 'visa';
    if (value.substring(0, 1) === '1') return 'UATP';

    return null;
  }
