import React from 'react';

export default function LinkListFooter(props) {
  return (
    <div className="footerLinks" {...props} >
      <a href="https://www.rossware.com/privacy-policy" style={{ fontSize: '8pt' }}>Privacy policy</a>
      <a href="https://www.rossware.com/terms" style={{ fontSize: '8pt' }}>Terms of Use</a>
      <a href="https://www.rossware.com/connect" style={{ fontSize: '8pt' }}>Contact us</a>
    </div>
  );
}
