import React, { Fragment } from 'react';

export default function HiddenFullsteamFields(props) {
  return (
    <Fragment>
      <input type="hidden" id="Amount" name="Amount"></input>
      <input type="hidden" id="InvoiceId" name="InvoiceId"></input>
      <input type="hidden" id="TransactionId" name="TransactionId"></input>
      <textarea readOnly hidden ref={props.responseTextAreaRef} id="HostedPaymentsResponse" name="HostedPaymentsResponse" style={{ fontSize: '8pt', height: '300px', width: '100%' }}></textarea>
    </Fragment>
  );
}
