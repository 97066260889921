import React from 'react';
import { getFirstNameFromPayerName } from '../helpers/strings';
import { getUrlParam } from '../helpers/urlParams';

export default function PaymentCompleteScreen(props) {
  const { payer, message } = props;

  let displayMessage = message;
  if (!displayMessage) {
    displayMessage = `Thank you for your payment, ${getFirstNameFromPayerName(payer || getUrlParam('payer_name'))}.`;
  }

  return (
    <div className='main-container'>
      <div className='fullsteampay-form'>
        <div className='payment-complete-text'>
          {displayMessage}
        </div>
      </div>
    </div>
  );
}
