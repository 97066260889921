import React from 'react';
import { isHostMobile } from '../helpers/host';
import LinkListFooter from './LinkListFooter';
import { CardOnFileLineItemAltE } from './CardOnFileLineItem';
import OrBar from './OrBar';

export default function TokenSelectionScreen(props) {
  const { tokens, onCancel, onChooseToken, switchScreen } = props;

  return (
    <div className="fullsteampay-form">
      <div className="manual-entry-header">
        <h4>Cards on file</h4>
      </div>
      <div className="padded-div">
        <p style={{ color: '#555' }}>Would you like to execute this payment using one of the stored cards?</p>

        <div className="list-group">
          {tokens.map((token, key) => {
            return <CardOnFileLineItemAltE onChooseToken={onChooseToken} token={token} key={key} />;
          })}
        </div>

        <OrBar />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button onClick={() => switchScreen('manual')} className="btn btn-primary">Enter card number</button>
          {!isHostMobile() && <button style={{ marginTop: '0.5rem' }} className="btn btn-secondary cancel-button" onClick={(event) => {
            onCancel?.();
          }}>Cancel</button>}
        </div>
        <LinkListFooter style={{ marginTop: 32 }}/>

      </div>
    </div>
  );
}
