import React from 'react';
import visa from '../assets/images/visa.svg';
import mastercard from '../assets/images/mastercard.svg';
import discovercard from '../assets/images/discovercard.png';
import amex from '../assets/images/amex.svg';

export default function BrandLogo(props) {
  const { brand, style, ...rest } = props;

  const lowerBrand = brand?.toLowerCase?.();
  if (lowerBrand === 'visa') {
    return <img style={{ height: 30, maxWidth: 42, ...style }} src={visa} alt="visa" {...rest} />
  } else if (lowerBrand === 'mastercard') {
    return <img style={{ height: 30, maxWidth: 42, ...style }} src={mastercard} alt="mastercard" {...rest} />
  } else if (lowerBrand === 'discovercard') {
    return <img style={{ height: 30, maxWidth: 42, ...style }} src={discovercard} alt="discovercard" {...rest} />
  } else if (lowerBrand === 'amex') {
    return <img style={{ height: 30, maxWidth: 42, ...style }} src={amex} alt="amex" {...rest} />
  }

  return (
    <div style={{ height: 30, maxWidth: 42, ...style }} {...rest}>{brand}</div>
  );
}