import React, { Fragment } from 'react';
import ValidityLabel from './ValidityLabel';

export default function LabeledValidityHostedInput(props) {
  const { valid, title, className, id } = props;

  return (
    <Fragment>
      <ValidityLabel valid={valid}>{title}</ValidityLabel>
      <div className={`${className} custom-input i-frame`} id={id}></div>
    </Fragment>
  );
}
