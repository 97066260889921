import _, { isString } from 'lodash';
import React, { Fragment, useCallback } from 'react';
import { FormControl } from 'react-bootstrap';
import { formatCurrency, formatStringAsValidNumber } from '../helpers/formatting';
import classes from './TipScreen.module.css';

function getTipAmountForPercentage(amount, percentage) {
  if (_.toString(percentage)?.indexOf('%') === -1) { return parseFloat(percentage).toFixed(2); } // if percentage does not include a percent sign, we conclude it is meant to be a flat rate.
  return (parseFloat(amount) * (parseFloat(percentage) / 100)).toFixed(2);
}

function formatDollarAmount(amount) {
  if (!amount) return '$0.00';
  if (isString(amount)) amount = parseFloat(amount);
  const response = amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  return response;
}

function TipButton(props) {
  const { children, onClick, selected, percent, style } = props;

  return (
    <button className={selected ? classes.selectedTipButton : classes.unselectedTipButton} style={{ padding: '16px 8px', ...style }} onClick={() => onClick(percent)}>
      {children}
    </button>
  );
}

function TipEntryButton(props) {
  const { amount, percent, onClick, style, selected, ...rest } = props;
  const dollarAmount = getTipAmountForPercentage(amount, percent);

  const tipValueIsPercent = percent?.indexOf('%') > -1;
  const tipValueToDisplay = tipValueIsPercent ? percent : formatCurrency(percent);

  return (
    <TipButton style={style} onClick={() => onClick(percent)} selected={selected} percent={percent} {...rest}>
      <h4 className={classes.zeroMargin}>{tipValueToDisplay}</h4>
      { tipValueIsPercent && <p className={classes.zeroMargin} style={{ color: selected ? '#fff' : '#aaa' }}>{formatCurrency(dollarAmount)}</p> }
    </TipButton>
  );
}

export default function TipScreen(props) {
  const { configuration, onFinish, amount } = props;
  const [selectedPercent, setSelectedPercent] = React.useState(null);
  const [customTip, setCustomTip] = React.useState(null);
  const [useCustom, setUseCustom] = React.useState(false);

  const choseButton = (percent) => {
    setUseCustom(false);
    setSelectedPercent(percent);
  }

  const setValidatedCustomTip = useCallback((event) => {
    setCustomTip(formatStringAsValidNumber(event.target.value));
  }, [setCustomTip]);

  const submittable = useCustom ? customTip !== null && customTip !== '' : selectedPercent !== null;

  const { default_tip_1, default_tip_2, default_tip_3, tip_text/* , show_no_tip_option */ } = configuration;
  // show_no_tip_option is temporarily ignored until we update the database with the correct value always

  return (
    <Fragment>
      <div className="fullsteampay-form">
        <div className="manual-entry-header">
          <h4>{tip_text}</h4>
        </div>
        <div className="padded-div">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 12 }}>
            <h4 className={classes.zeroMargin}>{formatDollarAmount(amount)}</h4>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}>
              <TipEntryButton style={{ marginRight: 8 }} selected={selectedPercent === default_tip_1} onClick={choseButton} amount={amount} percent={default_tip_1} />
              <TipEntryButton style={{ marginRight: 8 }} selected={selectedPercent === default_tip_2} onClick={choseButton} amount={amount} percent={default_tip_2} />
              <TipEntryButton selected={selectedPercent === default_tip_3} onClick={choseButton} amount={amount} percent={default_tip_3} />
            </div>

            <TipButton selected={useCustom} style={{ marginBottom: 8 }} onClick={() => {
              setSelectedPercent(null);
              setUseCustom(true);
              setTimeout(() => {
                const input = document.getElementById('custom-tip-input');
                if (input) input.focus();
              }, 0);
            }}>Custom Tip Amount</TipButton>
            {useCustom && (
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}>
                <FormControl
                  type="number"
                  id="custom-tip-input"
                  className={classes.tipInput}
                  value={customTip}
                  onChange={setValidatedCustomTip}
                  onBeforeInput={(e) => {
                    const value = e.target.value;
                    const hasDecimal = value.indexOf('.') !== -1;
                    const valueAfterDecimal = value.substring(value.indexOf('.'));
										const regex = new RegExp('^[0-9.]');
										if (e.data !== null && (!regex.test(e.data) || (hasDecimal && valueAfterDecimal.length > 2) || value.length >=8)) // 8 limit is arbitrary but should be enough
											e.preventDefault();
									}}
                />
              </div>
            )}
            <TipButton selected={selectedPercent === 0} onClick={() => choseButton(0)}>No Tip</TipButton>

            {submittable && <hr className={classes.hr}/>}

            {submittable && <button
              className={classes.submitButton}
              onClick={() => {
                const tipAmount = useCustom ? customTip : getTipAmountForPercentage(amount, selectedPercent);
                onFinish(tipAmount, selectedPercent); // selectedPercent is null when custom is selected
              }}
            >
              Submit
            </button>}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
