import React from 'react';
import { Table } from 'react-bootstrap';

function LineItem({ item }) {
  return (
    <tr>
      <th>{ item.description }</th>
      <th>{ item.quantity }</th>
      <th>{ item.cost }</th>
    </tr>
  );
}

export default function LineItemRenderer({ lineItemsArray }) {
  return (
    <Table bordered size="sm">
      <thead>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {lineItemsArray?.map((item, index) => <LineItem key={`line_item_${index}`} item={item} />)}
      </tbody>
      <thead>
        <tr>
          <th>Total</th>
          <th></th>
          <th>$33.00</th>
        </tr>
      </thead>
    </Table>
  );
}
