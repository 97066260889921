export function waitForElement(id, remainingAttempts = 50) {
  if (remainingAttempts === 0) {
    return Promise.reject(new Error('element not found'));
  }

  return new Promise((resolve, reject) => {
    const element = document.getElementById(id);
    if (element) {
      element.addEventListener('load', () => {
        resolve(element);
      });
    } else {
      setTimeout(() => {
        waitForElement(id, remainingAttempts - 1).then(resolve);
      }, 100);
    }
  });
}

export function waitForElements(ids) {
  return Promise.all(ids.map(id => waitForElement(id)));
}

export function waitForCardElements() {
  return waitForElements([
    'fullsteam-hosted-card-number-frame',
    'fullsteam-hosted-expiration-month-frame',
    'fullsteam-hosted-expiration-year-frame',
    'fullsteam-hosted-cvv-frame',
  ]);
}
