import Loader from "./Loader";

export default function ObscuringLoader () {
  return (
    <div className="giant-loading-div">
      <div className="center">
        <Loader />
      </div>
    </div>
  )
}