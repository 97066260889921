import React from 'react';
import moreHorizontal from '../assets/images/moreHorizontal.svg';
import BrandLogo from './BrandLogo';

export default function CardOnFileLineItem(props) {
  const { onChooseToken } = props;
  const {
    // token,
    brand,
    account_last_4,
    expiration_date,
    customer_name,
    // origin_invoice_number,
    // origin_app,
    // origin_transaction_id,
    // nickname,
    // customer_explicit_permission,
    // business_certified_permission,
    // is_test,
  } = props.token;

  return (
      <button onClick={() => onChooseToken?.(props.token)} className='list-group-item list-group-item-action'>
      <div style={{ display: 'flex', flexDirection: 'row' }} >
        <div style={{ flex: 1 }}>
          <div><strong>{customer_name}</strong></div>
          <div>**** **** **** {account_last_4}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <BrandLogo brand={brand} />
            <div style={{ fontSize: 12 }}>{expiration_date}</div>
          </div>
        </div>
      </div>
    </button>
  );
}

export function CardOnFileLineItemAltB(props) {
  const { onChooseToken } = props;
  const {
    // token,
    brand,
    account_last_4,
    expiration_date,
    customer_name,
    // origin_invoice_number,
    // origin_app,
    // origin_transaction_id,
    nickname,
    // customer_explicit_permission,
    // business_certified_permission,
    // is_test,
  } = props.token;

  return (
      <button onClick={() => onChooseToken?.(props.token)} className='list-group-item list-group-item-action'>
        <div style={{ display: 'flex', flexDirection: 'row' }} >
          <div style={{ flex: 1 }}>
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><strong>{nickname || customer_name}</strong></div>
            <div>**** **** **** {account_last_4}</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <BrandLogo brand={brand} />
              <div style={{ fontSize: 12 }}>{expiration_date}</div>
            </div>
          </div>
        </div>
      </button>
  );
}

export function CardOnFileLineItemAltC(props) {
  const { onChooseToken } = props;
  const {
    // token,
    brand,
    account_last_4,
    expiration_date,
    customer_name,
    // origin_invoice_number,
    // origin_app,
    // origin_transaction_id,
    nickname,
    // customer_explicit_permission,
    // business_certified_permission,
    // is_test,
  } = props.token;

  return (
      <button onClick={() => onChooseToken?.(props.token)} className='list-group-item list-group-item-action'>
        <div style={{ display: 'flex', flexDirection: 'row' }} >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
            <BrandLogo brand={brand} />
          </div>
          <div style={{ flex: 1 }}>
            <div><strong>{nickname}</strong></div>
            <div>{customer_name}</div>
            <div>**** **** **** {account_last_4}</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <img src={moreHorizontal} alt="more button" style={{ width: 24 }} />
            <div style={{ flex: 1 }}/>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <div style={{ fontSize: 12 }}>{expiration_date}</div>
            </div>
          </div>
        </div>
      </button>
  );
}

export function CardOnFileLineItemAltD(props) {
  const { onChooseToken } = props;
  const {
    // token,
    brand,
    account_last_4,
    expiration_date,
    customer_name,
    // origin_invoice_number,
    // origin_app,
    // origin_transaction_id,
    nickname,
    // customer_explicit_permission,
    // business_certified_permission,
    // is_test,
  } = props.token;

  return (
      <button onClick={() => onChooseToken?.(props.token)} className='list-group-item list-group-item-action'>
        <div style={{ display: 'flex', flexDirection: 'row' }} >
          <div style={{ flex: 1 }}>
            <div><strong>{nickname}</strong></div>
            <div>{customer_name}</div>
            <div>**** **** **** {account_last_4}</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <div className='btn' style={{ padding: 0, margin: 0 }}><img src={moreHorizontal} alt="more button" style={{ width: 24 }} /></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <BrandLogo brand={brand} />
              <div style={{ fontSize: 12 }}>{expiration_date}</div>
            </div>
          </div>
        </div>
      </button>
  );
}

export function CardOnFileLineItemAltE(props) {
  const { onChooseToken } = props;
  const {
    // token,
    brand,
    account_last_4,
    expiration_date,
    customer_name,
    // origin_invoice_number,
    // origin_app,
    // origin_transaction_id,
    nickname,
    // customer_explicit_permission,
    // business_certified_permission,
    // is_test,
  } = props.token;

  return (
      <button onClick={() => onChooseToken?.(props.token)} className='list-group-item list-group-item-action'>
        <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }} >
          <div style={{ flex: 1, overflow: 'hidden' }}>
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><strong>{nickname}</strong></div>
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{customer_name}</div>
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>**** **** **** {account_last_4}</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <BrandLogo brand={brand} />
              <div style={{ fontSize: 12 }}>{expiration_date}</div>
            </div>
          </div>
        </div>
      </button>
  );
}
