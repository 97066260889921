export function isHostMobile() {
  return !!window.ReactNativeWebView;
}

export function sendMessageToHost(message) {
  if (isHostMobile()) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  } else {
    console.log('no known host to send message to');
  }
}
