export function validateNonFullsteamField(type, value) {
  switch (type) {
    case 'zip':
      if (value.match(/^\d{5}$/) || value.match(/^([A-Za-z]\d[A-Za-z][-]?\d[A-Za-z]\d)/)) {
        return true;
      } else {
        return false;
      }
    case 'fullname':
      return (value.match(/^[a-z ,.'-]+$/i));
    default: return false;
  }
}

export function getValidationMessages(validations) {
  if (validations.cc !== null && validations.cvv !== null && validations.expmonth !== null && validations.expyear !== null) {
    const fields = [
      { label: 'Card number', status: validations.cc },
      { label: 'Security code', status: validations.cvv },
      { label: 'Expiration month', status: validations.expmonth },
      { label: 'Expiration year', status: validations.expyear },
    ];

    const invalids = fields.filter(item => item.status === false);

    const messages = [];
    if (invalids.length > 0) {
      messages.push(`Invalid field${invalids.length > 1 ? 's' : ''}: ${invalids.map(item => item.label).join(', ')}`);
    }

    return messages;
  }

  return [];
}
