import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { captureRosswarePayTransaction, recordRosswarepayTransaction, saveAsCardOnFile } from '../helpers/api';
import { getValidationMessages, validateNonFullsteamField } from '../helpers/validations';
import { hostedPaymentsPerformBinCheck } from '../helpers/cardBin';
import { getExpectedUrlParams } from '../helpers/urlParams';
import { isHostMobile, sendMessageToHost } from '../helpers/host';
import LineItemRenderer from './LineItemRenderer';
import { useFullsteam } from '../helpers/useFullsteam';
import CardLogoHeader from './CardLogoHeader';
import HiddenFullsteamFields from './HiddenFullsteamFields';
import LinkListFooter from './LinkListFooter';
import LabeledValidityInput from './LabeledValidityInput';
import LabeledValidityHostedInput from './LabeledValidityHostedInput';
import LabeledCheckbox from './LabeledCheckbox';
import { isProduction } from '../helpers/environment';
import { LabeledInput } from './LabeledInput';
import { padStart } from 'lodash';
import OrBar from './OrBar';
import ChargeBreakdown from './ChargeBreakdown';
import ObscuringLoader from './ObscuringLoader';
import Modal from './Modal';
import parseBoolFromParam from '../helpers/boolean';
import { formatCurrency } from '../helpers/formatting';
import currency from 'currency.js';

const params = getExpectedUrlParams();

export default function ManualPaymentScreen(props) {
  const { authenticationKey, controlScriptLoaded, tokensEnabled, enableMobileTokens, tokens, switchScreen, setGlobalError, onPaymentSuccess, modifiedAmounts } = props;

  // general loading screen (overlays everything else)
  const [loading, setLoading] = useState(true);

  // loading for payment result (small line loader at bottom of form)
  const [loadingPaymentResult, setLoadingPaymentResult] = useState(false);

  const [showForceDuplicate, setShowForceDuplicate] = useState(false);
  const [forceDuplicate, setForceDuplicate] = useState(false);

  // error for the payment (only shows in <Alert>)
  const [processingError, setProcessingError] = useState(null);

  // validations for field styling
  const [payerNameValid, setPayerNameValid] = useState(true);
  const [zipValid, setZipValid] = useState(true);

  const [wantValidationMessage, setWantValidationMessage] = useState(false); // when we submit, request a validation message, only show it to the user after we get actual validation data, ignore nulls

  const gobuttonref = useRef();

  const [lineItems] = useState(null);
  const [storeToken, setStoreToken] = useState(false);
  const [explicitPermission, setExplicitPermission] = useState(false);
  const [tokenNickname, setTokenNickname] = useState('');
  const [payerName, setPayerName] = useState(params.payer_name);
  const [submitting, setSubmitting] = useState(false);

  const [showExcessiveChargeWarning, setShowExcessiveChargeWarning] = useState(false);

  const { validations, errors } = useFullsteam({
    disableDuplicateDetection: forceDuplicate,
    authenticationKey,
    scriptLoaded: controlScriptLoaded,
    operationType: 'Authorization',
    cardEntryContext: 'WebConsumerInitiated',
    formId: 'fullsteam-hosted-form',

    includeToken: tokensEnabled, // always request the token, but only store it if the user checked the box

    nameOnAccountField: 'fullsteam-hosted-name-on-card-input',
    zipField: 'fullsteam-hosted-zip-input',

    nameOnAccount: payerName, // this has to be null for fullsteam to pull the value from the field, alternatively we could have the field be a controlled component and pass through the value
    paymentAmount: modifiedAmounts?.total,
    taxAmount: params.tax,

    customerId: params.business_id,
    invoiceNumber: params.invoice_number || params.unique_id, // todo: fully remove unique_id, must be done after sdm 2.0.2 is removed from play

    ignoreFormSubmit: false,
    onError: (err) => {
      console.log('ERRORED and called onError function, error is: ', err);
      const errorComponents = err?.split?.(':');

      // error message format
      // 1023: Transaction declined because duplicate
      if (errorComponents?.[0]?.trim() === '1023') {
        // this will basically never happen because authorizations never fail on duplicates except for when manually triggered with 2.59 amount on dev
        setShowForceDuplicate(true);
      }

      sendMessageToHost({ unique_id: params.unique_id, error: err, context: 'processing', success: false, done: false });
      setSubmitting(false);
    },
    showPleaseWait: () => {
      setLoadingPaymentResult(true)
    },
    hidePleaseWait: () => {
      setLoadingPaymentResult(false)
    },
    onValidation: (valid) => {
      if (!valid) {
        setSubmitting(false);
      }
    },
    validationCallback: () => {
      // maybe show validation error here
      return true;
    },
    completionCallback: async () => {
      try {
        const responseJson = JSON.parse(responseTextAreaRef.current.value);
        console.log('got response from fullsteam', responseJson);

        if (responseJson?.gatewayResponse?.accountDetails) {
          responseJson.gatewayResponse.accountDetails.cardBrand = hostedPaymentsPerformBinCheck(responseJson?.gatewayResponse?.accountDetails?.cardBIN);
        }
        // such as ae132cac-2c07-4995-a920-b18930268c45

        if (responseJson?.gatewayResponse?.token && explicitPermission && storeToken) {
          const tokenResponse = await saveAsCardOnFile({
            token: responseJson.gatewayResponse?.token,
            transaction_id: responseJson.gatewayResponse?.transactionID,
            invoice_number: params?.invoice_number,
            account_last_4: responseJson.gatewayResponse?.accountDetails?.paymentAccountLast4,
            nickname: tokenNickname,
            description: '',
            business_certified_permission: explicitPermission,
            customer_name: responseJson.gatewayResponse?.accountDetails?.nameOnAccount,
            is_test: !isProduction(),
            expiration_date: padStart(responseJson.gatewayResponse?.accountDetails?.expirationMonth, 2, '0') + '/' + padStart(responseJson.gatewayResponse?.accountDetails?.expirationYear, 4, '0'),
            app: 'terminal.rosswarepay.com',
            brand: responseJson.gatewayResponse?.accountDetails?.cardBrand,
          });

          console.log('saved token', tokenResponse);
        }

        // execute the capture
        let captureResponse;

        captureResponse = await captureRosswarePayTransaction({
          transaction_id: responseJson.gatewayResponse?.transactionID,
          amount: modifiedAmounts?.total,
          invoice_number: params?.invoice_number,
          force_duplicate: parseBoolFromParam(params?.force_duplicate),
        });

        const captureResponseErrorDetails = captureResponse?.data?.responseDetails.map(responseDetail => {
          return responseDetail?.message;
        }).join(',');

        if (captureResponse?.data?.isSuccessful === false) {
          if (captureResponse?.data?.responseCode === 1023) {
            const userForcedDupe = window.confirm(`You appear to be attempting a duplicate transaction. Are you absolutely sure you want to re-run the customer's payment in the amount of ${formatCurrency(modifiedAmounts?.total)}?`);
            if (userForcedDupe) {
              captureResponse = await captureRosswarePayTransaction({
                transaction_id: responseJson.gatewayResponse?.transactionID,
                amount: modifiedAmounts?.total,
                invoice_number: params?.invoice_number,
                force_duplicate: true,
              });
              if (captureResponse?.data?.isSuccessful === false) {
                throw new Error(
                  captureResponse?.data?.responseDetails.map(responseDetail => {
                    return responseDetail?.message;
                  }).join(',')
                );
              }
            } else {
              throw new Error('Transaction cancelled')
            }
          } else {
            throw new Error(captureResponseErrorDetails);
          }
        }

        responseJson.captureResponse = captureResponse?.data;

        await recordRosswarepayTransaction({
          unique_id: params?.unique_id,
          fullsteam_response: responseJson,
          invoice_number: params?.invoice_number,
          method: 'rossware_pay_terminal',
          requester_id: params?.requester_id,
          platform_fee: captureResponse?.data?.isSuccessful ? modifiedAmounts.platform_fee : undefined, // don't record the platform fee if the payment wasn't successful
          platform_fee_basis: modifiedAmounts.platform_fee_amount,
          platform: params.platform,
        });

        onPaymentSuccess({ transaction_id: responseJson?.gatewayResponse?.transactionID, unique_id: params?.unique_id, response: responseJson, success: true, done: true });

      } catch (err) {
        setGlobalError(err?.message);
      }

      setSubmitting(false);
      clearInterval(window.pingerInterval);
    },
    onLoad: () => {
      console.log('on load completed');
      setLoading(false);
    },
  });

  const responseTextAreaRef = useRef(null);

  useEffect(() => {
    if (wantValidationMessage) {
      const messages = getValidationMessages(validations);

      if (messages.length > 0) {
        const errorMessage = messages.join('\n');
        setProcessingError(errorMessage);
        sendMessageToHost({ unique_id: params.unique_id, error: errorMessage, context: 'validation', success: false });
      }
      setWantValidationMessage(false);
    }
  }, [wantValidationMessage, validations, setProcessingError, setWantValidationMessage]);

  useEffect(() => {
    if (props.modifiedAmounts?.total > 2500) {
      setShowExcessiveChargeWarning(true);
    }
  }, [props]);

  const showValidationErrorOnce = useCallback(async (continuedAttemptNumber, retriesAvailable = 100) => {
    setWantValidationMessage(true);
  }, []);

  const failedDualCheck = (storeToken && tokensEnabled && !explicitPermission);

  return (
    <Fragment>
      {loading && <ObscuringLoader />}
      {!!showForceDuplicate && (
        <Modal style={{ display: 'flex', flexDirection: 'column' }}>
          <h2>Double Charge Warning</h2>
          <p>You already charged this card {currency(params.amount).format()}, do you <strong>really</strong> want to run that same payment again?</p>
          <button style={{ backgroundColor: '#f33', color: '#fff', border: 0 }} className="btn btn-primary quickSpace" onClick={() => {
            setShowForceDuplicate(false);
            setSubmitting(false);
          }}>Cancel Payment</button>
          <button style={{ backgroundColor: '#eaeaea' }} className="btn quickSpace" onClick={() => {
            setShowForceDuplicate(false);
            setProcessingError(null);
            setForceDuplicate(true);

            // this is a hack to get data to the hosted form
            setTimeout(() => {
              gobuttonref?.current?.click();
            }, 5);
          }}>Charge them ${params.amount} again</button>
        </Modal>
      )}
      {
        !!showExcessiveChargeWarning && (
          <Modal style={{ display: 'flex', flexDirection: 'column' }}>
            <h2>Large Transaction Warning</h2>
            <p>You are attempting an unusually large charge of {currency(params.amount).format()}. Please be very confident this is the amount you intend before continuing.</p>
            <button style={{ backgroundColor: '#f33', color: '#fff', border: 0 }} className="btn btn-primary quickSpace" onClick={() => {
              setGlobalError('Payment cancelled');
            }}>Cancel Payment</button>
            <button style={{ backgroundColor: '#eaeaea', marginTop: '8px' }} className="btn quickSpace" onClick={() => {
              setShowExcessiveChargeWarning(false);
            }}>Continue</button>
          </Modal>
        )
      }
      <form id="fullsteam-hosted-form" className="fullsteampay-form" onSubmit={(event) => {
        event.preventDefault();
        setSubmitting(true);
        setProcessingError(null);
        showValidationErrorOnce();
      }}>
        <div className="manual-entry-header">
          <h4>Manual card entry</h4>
        </div>

        <CardLogoHeader />

        <ChargeBreakdown modifiedAmounts={modifiedAmounts}/>

        <div className="padded-div">
          { lineItems && <LineItemRenderer lineItemsArray={lineItems} />}

          <LabeledValidityInput valid={payerNameValid} title="Name on card" id="fullsteam-hosted-name-on-card-input" defaultValue={params?.payer_name || ''} label="fullname" validator={validateNonFullsteamField} onValidate={setPayerNameValid} onChange={setPayerName} />
          <LabeledValidityHostedInput valid={validations?.cc} title="Card number" id="fullsteam-hosted-card-number-div"/>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' }}>
            <div><LabeledValidityHostedInput valid={validations?.expmonth} title="Expiration month" id="fullsteam-hosted-expiration-month-div"/></div>
            <div><LabeledValidityHostedInput valid={validations?.expyear} title="Expiration year" id="fullsteam-hosted-expiration-year-div"/></div>
          </div>
          <LabeledValidityHostedInput valid={validations?.cvv} title="CVV" id="fullsteam-hosted-cvv-div"/>
          <LabeledValidityInput valid={zipValid} title="Zip/Postal code" id="fullsteam-hosted-zip-input" defaultValue={params?.zip_code || ''} label="zip" validator={validateNonFullsteamField} onValidate={setZipValid} />

          {tokensEnabled &&
            <Fragment>
              {tokensEnabled && <LabeledCheckbox title="Store as card on file" value={storeToken} onClick={() => setStoreToken(!storeToken)}/>}
              {storeToken && tokensEnabled && <LabeledCheckbox style={{ color: 'red' }} title="Customer gave permission to save card" value={explicitPermission} onClick={() => setExplicitPermission(!explicitPermission)}/>}
              {storeToken && tokensEnabled && <LabeledInput labelStyle={{ marginTop: 10 }} title="Card on file nickname" valid={!!tokenNickname} value={tokenNickname} onChange={setTokenNickname} />}
            </Fragment>
          }

          { loadingPaymentResult &&
            <div className="progress" id="loaderBar" style={{ height: '3px' }}>
              <div className="progress-bar" id="loaderBarInner" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          }

          { processingError && <Alert style={{ marginTop: '5px', marginBottom: '0' }} variant='danger'>{JSON.stringify(processingError)}</Alert>}
          { errors && <Alert style={{ marginTop: '5px', marginBottom: '0' }} variant='danger'>{JSON.stringify(errors)}</Alert>}
          {!failedDualCheck && !submitting && <input ref={gobuttonref} disabled={submitting} type="submit" value="Submit payment" className={`btn btn-primary`}></input>}
          {failedDualCheck && <div style={{ color: 'red', fontSize: 14 }}>Either check customer permission or uncheck store card to unlock submit button</div>}

          <HiddenFullsteamFields responseTextAreaRef={responseTextAreaRef}/>

          {(!!tokens?.length && tokensEnabled && isHostMobile() && enableMobileTokens) && <Fragment>
            <OrBar />
            <button disabled={submitting} className="btn btn-secondary" onClick={(event) => {
              console.log('button clicked');
              event.preventDefault();
              switchScreen('choose-token');
            }}>Go to cards on file</button>
          </Fragment>}
          {!isHostMobile() && <button style={{ marginTop: "0.5rem" }} disabled={submitting} className="btn btn-secondary cancel-button" onClick={(event) => {
            event.preventDefault();
            setGlobalError('Payment cancelled');
          }}>Cancel</button>}

          <LinkListFooter />
        </div>
      </form>
    </Fragment>
  );
}
