import React from 'react';

export default function LabeledCheckbox(props) {
  const { onClick, title, value, style } = props;

  return (
    <div unselectable="on" className="unselectable canclick" onClick={onClick} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 8, paddingBottom: 8, paddingRight: 8, ...style }}>
      <label className="canclick">{title}</label>
      <div style={{ flex: 1 }}></div>
      <input className='canclick' checked={value} type="checkbox" onChange={() => {}} />
    </div>
  );
}
