import React from 'react';

import visa from '../assets/images/visa.svg';
import mastercard from '../assets/images/mastercard.svg';
import discovercard from '../assets/images/discovercard.png';
import amex from '../assets/images/amex.svg';

export default function CardLogoHeader(props) {
  const { highlight } = props;
  let logoStyles = {};

  if (highlight) {
    logoStyles.visa = { opacity: highlight === 'visa' ? 1 : .2 };
    logoStyles.amex = { opacity: highlight === 'amex' ? 1 : .2 };
    logoStyles.mastercard = { opacity: highlight === 'mastercard' ? 1 : .2 };
  }

  return (
    <div className="credit-card-image-container">
      <img className="credit-card-image" src={visa} alt="visa" />
      <img className="credit-card-image" src={mastercard} alt="mastercard" />
      <img className="credit-card-image" src={discovercard} alt="discovercard" />
      <img className="credit-card-image" src={amex} alt="amex" />
    </div>
  );
}
