import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { executeRefund, recordRosswarepayTransaction } from '../helpers/api';
import { getValidationMessages, validateNonFullsteamField } from '../helpers/validations';
import { getExpectedUrlParams } from '../helpers/urlParams';
import { isHostMobile, sendMessageToHost } from '../helpers/host';
import LineItemRenderer from './LineItemRenderer';
import { useFullsteam } from '../helpers/useFullsteam';
import HiddenFullsteamFields from './HiddenFullsteamFields';
import LinkListFooter from './LinkListFooter';
import LabeledValidityInput from './LabeledValidityInput';
import LabeledValidityHostedInput from './LabeledValidityHostedInput';
import PaymentCompleteScreen from './PaymentCompleteScreen';

const params = getExpectedUrlParams();

export default function RefundScreen(props) {
  const { authenticationKey, controlScriptLoaded, setGlobalError } = props;

  // used for showing the 'complete' page
  const [paymentComplete, setPaymentComplete] = useState(false);

  // general loading screen (overlays everything else)
  const [loading, setLoading] = useState(true);

  // loading for payment result (small line loader at bottom of form)
  const [loadingPaymentResult, setLoadingPaymentResult] = useState(false);

  // error for the payment (only shows in <Alert>)
  const [processingError, setProcessingError] = useState(null);

  // validations for field styling
  const [payerNameValid, setPayerNameValid] = useState(true);
  const [zipValid, setZipValid] = useState(true);

  const [wantValidationMessage, setWantValidationMessage] = useState(false); // when we submit, request a validation message, only show it to the user after we get actual validation data, ignore nulls

  const [lineItems] = useState(null);
  const [payerName, setPayerName] = useState(params.payer_name);
  const [submitting, setSubmitting] = useState(false);

  const { validations, errors } = useFullsteam({
    disableDuplicateDetection: process.env.REACT_APP_ROSSWARE_PAY_ENVIRONMENT === 'test' ? true : false,
    authenticationKey,
    scriptLoaded: controlScriptLoaded,
    operationType: 'Token',
    cardEntryContext: 'WebConsumerInitiated',
    formId: 'fullsteam-hosted-form',

    // includeToken: tokensEnabled, // always request the token, but only store it if the user checked the box

    nameOnAccountField: 'fullsteam-hosted-name-on-card-input',
    zipField: 'fullsteam-hosted-zip-input',

    nameOnAccount: payerName, // this has to be null for fullsteam to pull the value from the field, alternatively we could have the field be a controlled component and pass through the value

    // not available for tokens
    // paymentAmount: params.amount,
    // taxAmount: params.tax,

    customerId: params.business_id,
    invoiceNumber: params.invoice_number || params.unique_id, // todo: fully remove unique_id, must be done after sdm 2.0.2 is removed from play

    ignoreFormSubmit: false,
    onError: (err) => {
      sendMessageToHost({ unique_id: params.unique_id, error: err, context: 'processing', success: false, done: false });
      setSubmitting(false);
    },
    showPleaseWait: () => {
      setLoadingPaymentResult(true)
    },
    hidePleaseWait: () => {
      setLoadingPaymentResult(false)
    },
    onValidation: (valid) => {
      if (!valid) {
        setSubmitting(false);
      }
    },
    validationCallback: () => {
      // maybe show validation error here
      return true;
    },
    completionCallback: async () => {
      setLoadingPaymentResult(false);
      console.log('completionCallback', responseTextAreaRef.current.value);

      const responseJson = JSON.parse(responseTextAreaRef.current.value);
      console.log('got response from fullsteam', responseJson);

      const token = responseJson?.gatewayResponse?.token
      const refundResponse = await executeRefund({ token, amount: params.amount, invoice_number: params.invoice_number, business_id: params.business_id, business_password: params.business_password });

      if (refundResponse.status !== 200 || refundResponse?.data?.data?.isSuccessful === false) {
        setSubmitting(false);
        setProcessingError(refundResponse.data?.data?.responseDetails?.[0] || refundResponse.data);
      } else {
        try {
          await recordRosswarepayTransaction({
            unique_id: params.unique_id,
            fullsteam_response: { gatewayResponse: refundResponse?.data?.data },
            requester_id: params.requester_id,
            invoice_number: params.invoice_number,
            platform: params.platform,
          });

          setPaymentComplete(true);
          sendMessageToHost({ unique_id: params?.unique_id, response: responseJson, success: true, done: true });
        } catch (err) {
          setGlobalError(err);
        }

        setSubmitting(false);
        clearInterval(window.pingerInterval);
      }
    },
    onLoad: () => {
      console.log('on load completed');
      setLoading(false);
    },
  });

  const responseTextAreaRef = useRef(null);

  useEffect(() => {
    if (wantValidationMessage) {
      const messages = getValidationMessages(validations);

      if (messages.length > 0) {
        const errorMessage = messages.join('\n');
        setProcessingError(errorMessage);
        sendMessageToHost({ unique_id: params.unique_id, error: errorMessage, context: 'validation', success: false });
      }
      setWantValidationMessage(false);
    }
  }, [wantValidationMessage, validations, setProcessingError, setWantValidationMessage]);

  const showValidationErrorOnce = useCallback(async (continuedAttemptNumber, retriesAvailable = 100) => {
    setWantValidationMessage(true);
  }, []);

  if (paymentComplete) {
    return <PaymentCompleteScreen message={`Refunded $${params.amount} to ${payerName}`} />;
  }

  return (
    <Fragment>
      {loading && <div className="giant-loading-div"><div className="center"><Spinner variant="primary" animation="border" /><br />{ loading }</div></div>}
      <form id="fullsteam-hosted-form" className="fullsteampay-form" onSubmit={(event) => {
        event.preventDefault();
        setSubmitting(true);
        setProcessingError(null);
        showValidationErrorOnce();
      }}>
        <div className="manual-entry-header">
          <h4>Issuing a refund</h4>
        </div>
        <div className="padded-div">
          { lineItems && <LineItemRenderer lineItemsArray={lineItems} />}
          <div style={{ color: '#400' }}><em>Refunding customer</em></div>
          <div style={{ color: '#400' }} className="pay-amount">${params?.amount}</div>

          <LabeledValidityInput valid={payerNameValid} title="Name on card" id="fullsteam-hosted-name-on-card-input" defaultValue={params?.payer_name || ''} label="fullname" validator={validateNonFullsteamField} onValidate={setPayerNameValid} onChange={setPayerName} />
          <LabeledValidityHostedInput valid={validations?.cc} title="Card number" id="fullsteam-hosted-card-number-div"/>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' }}>
            <div><LabeledValidityHostedInput valid={validations?.expmonth} title="Expiration month" id="fullsteam-hosted-expiration-month-div"/></div>
            <div><LabeledValidityHostedInput valid={validations?.expyear} title="Expiration year" id="fullsteam-hosted-expiration-year-div"/></div>
          </div>
          <LabeledValidityHostedInput valid={validations?.cvv} title="CVV" id="fullsteam-hosted-cvv-div"/>
          <LabeledValidityInput valid={zipValid} title="Zip/Postal code" id="fullsteam-hosted-zip-input" defaultValue={params?.zip_code || ''} label="zip" validator={validateNonFullsteamField} onValidate={setZipValid} />

          { loadingPaymentResult &&
            <div className="progress" id="loaderBar" style={{ height: '3px' }}>
              <div className="progress-bar" id="loaderBarInner" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          }

          { processingError && <Alert style={{ marginTop: '5px', marginBottom: '0' }} variant='danger'>{JSON.stringify(processingError)}</Alert>}
          { errors && <Alert style={{ marginTop: '5px', marginBottom: '0' }} variant='danger'>{JSON.stringify(errors)}</Alert>}

          <HiddenFullsteamFields responseTextAreaRef={responseTextAreaRef}/>

          <input disabled={submitting} type="submit" value="Execute refund" className={`btn btn-warning`}></input>

          {!isHostMobile() && <button style={{ marginTop: '0.5rem' }} disabled={submitting} className="btn btn-primary cancel-button" onClick={(event) => {
            event.preventDefault();
            setGlobalError('Refund cancelled');
          }}>Cancel</button>}

          <LinkListFooter />

        </div>
      </form>
    </Fragment>
  );
}
