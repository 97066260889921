export function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(amount);
}

// sample "  abc123.345...!"
export function formatStringAsValidNumber(string) {
  if (!string) return '';
  string = string.trim(); // "abc123.345...!"
  const isNegative = (string[0] === '-'); // false
  string = string.replace(/[^\d.]/g, ''); // "123.345..."
  const parts = string.split('.'); // ["123", "345", "", "", ""]
  const firstPart = parts.shift(); // "123" (parts becomes ["345", "", "", ""])
  if (parts.length === 0) {
    return (isNegative ? '-' : '') + firstPart;
  }

  const lastPart = parts.join('').replace(/\./g, ''); // "345"
  return (isNegative ? '-' : '') + firstPart + '.' + lastPart; // "123.345"
}

// export function formatStringAsValidNumber2(string) {
//   if (!string) return '';
//   string = string.trim();
//   const isNegative = (string[0] === '-');
//   const dotPosition = string.indexOf('.');
//   if (dotPosition !== -1) {
//     const firstPart = string.substring(0, dotPosition);
//     const lastPart = string.substring(dotPosition + 1, string.length);
//     return (isNegative ? '-' : '') + firstPart + '.' + lastPart;
//   }
//   return (isNegative ? '-' : '') + string;
// }

// export function wildcardValidator(string) {
//   return string.replace(/[^0-9.]/g, '').replace('.','_').replaceAll('.','').replace('_','.');
// }
