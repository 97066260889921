import React, { Fragment } from 'react';

export function LabeledInput(props) {
  const {
    valid,
    title,
    className,
    id,
    value,
    defaultValue,
    onChange,
    labelStyle,
    inputStyle,
  } = props;

  return (
    <Fragment>
      <label style={labelStyle}>{title}</label>
      <input style={inputStyle} className={`${className} custom-input i-frame ${ !valid && 'fullSteamFilledDivInvalid' }`} id={id} defaultValue={defaultValue} value={value} onChange={(event) => onChange(event.target.value)}></input>
    </Fragment>
  );
}
